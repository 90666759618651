
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class extends Vue {
  private showtext = false;

  private toggleText(text: any) {
    this.showtext = this.showtext === text ? null : text;
  }

  private goBack() {
    this.$router.go(-1);
  }
}
